/* routes/Play.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	update_await_block_branch
} from "svelte/internal";

import Loading from "../components/Loading.svelte";
import PageTitle from "../components/PageTitle.svelte";
import Error from "../components/Error.svelte";
import Tabs from "../components/Tabs.svelte";
import CompLink from "../components/CompLink.svelte";
import Info from "../components/Info.svelte";
import { get } from "../utils/api";

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (23:0) <PageTitle>
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Competitions");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (51:0) {:catch}
function create_catch_block(ctx) {
	let error;
	let current;

	error = new Error({
			props: { message: "loading the comps..." }
		});

	return {
		c() {
			create_component(error.$$.fragment);
		},
		m(target, anchor) {
			mount_component(error, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(error.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(error.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(error, detaching);
		}
	};
}

// (26:0) {:then comps}
function create_then_block(ctx) {
	let tabs;
	let current;

	tabs = new Tabs({
			props: {
				tabs: ["current", "past"],
				$$slots: {
					tab2: [create_tab2_slot],
					tab1: [create_tab1_slot]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(tabs.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tabs, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tabs_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				tabs_changes.$$scope = { dirty, ctx };
			}

			tabs.$set(tabs_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tabs.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tabs.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tabs, detaching);
		}
	};
}

// (34:8) {:else}
function create_else_block_1(ctx) {
	let info;
	let current;

	info = new Info({
			props: {
				message: "No comps started in the last 6 months."
			}
		});

	return {
		c() {
			create_component(info.$$.fragment);
		},
		m(target, anchor) {
			mount_component(info, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(info.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(info.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(info, detaching);
		}
	};
}

// (30:8) {#each currentComps(comps) as comp}
function create_each_block_1(ctx) {
	let li;
	let complink;
	let t;
	let current;
	complink = new CompLink({ props: { comp: /*comp*/ ctx[5] } });

	return {
		c() {
			li = element("li");
			create_component(complink.$$.fragment);
			t = space();
		},
		m(target, anchor) {
			insert(target, li, anchor);
			mount_component(complink, li, null);
			append(li, t);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(complink.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(complink.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(li);
			destroy_component(complink);
		}
	};
}

// (28:4) 
function create_tab1_slot(ctx) {
	let div;
	let ul;
	let current;
	let each_value_1 = /*currentComps*/ ctx[1](/*comps*/ ctx[4]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	let each_1_else_1 = null;

	if (!each_value_1.length) {
		each_1_else_1 = create_else_block_1(ctx);
	}

	return {
		c() {
			div = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			if (each_1_else_1) {
				each_1_else_1.c();
			}

			attr(div, "slot", "tab1");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			if (each_1_else_1) {
				each_1_else_1.m(ul, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*currentComps, compsPromise*/ 3) {
				each_value_1 = /*currentComps*/ ctx[1](/*comps*/ ctx[4]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(ul, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();

				if (!each_value_1.length && each_1_else_1) {
					each_1_else_1.p(ctx, dirty);
				} else if (!each_value_1.length) {
					each_1_else_1 = create_else_block_1(ctx);
					each_1_else_1.c();
					transition_in(each_1_else_1, 1);
					each_1_else_1.m(ul, null);
				} else if (each_1_else_1) {
					group_outros();

					transition_out(each_1_else_1, 1, 1, () => {
						each_1_else_1 = null;
					});

					check_outros();
				}
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (each_1_else_1) each_1_else_1.d();
		}
	};
}

// (45:8) {:else}
function create_else_block(ctx) {
	let info;
	let current;

	info = new Info({
			props: { message: "No comps older then 6 months." }
		});

	return {
		c() {
			create_component(info.$$.fragment);
		},
		m(target, anchor) {
			mount_component(info, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(info.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(info.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(info, detaching);
		}
	};
}

// (41:8) {#each pastComps(comps) as comp}
function create_each_block(ctx) {
	let li;
	let complink;
	let t;
	let current;
	complink = new CompLink({ props: { comp: /*comp*/ ctx[5] } });

	return {
		c() {
			li = element("li");
			create_component(complink.$$.fragment);
			t = space();
		},
		m(target, anchor) {
			insert(target, li, anchor);
			mount_component(complink, li, null);
			append(li, t);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(complink.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(complink.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(li);
			destroy_component(complink);
		}
	};
}

// (39:4) 
function create_tab2_slot(ctx) {
	let div;
	let ul;
	let current;
	let each_value = /*pastComps*/ ctx[2](/*comps*/ ctx[4]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	let each_1_else = null;

	if (!each_value.length) {
		each_1_else = create_else_block(ctx);
	}

	return {
		c() {
			div = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			if (each_1_else) {
				each_1_else.c();
			}

			attr(div, "slot", "tab2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			if (each_1_else) {
				each_1_else.m(ul, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*pastComps, compsPromise*/ 5) {
				each_value = /*pastComps*/ ctx[2](/*comps*/ ctx[4]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(ul, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();

				if (!each_value.length && each_1_else) {
					each_1_else.p(ctx, dirty);
				} else if (!each_value.length) {
					each_1_else = create_else_block(ctx);
					each_1_else.c();
					transition_in(each_1_else, 1);
					each_1_else.m(ul, null);
				} else if (each_1_else) {
					group_outros();

					transition_out(each_1_else, 1, 1, () => {
						each_1_else = null;
					});

					check_outros();
				}
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (each_1_else) each_1_else.d();
		}
	};
}

// (24:21)    <Loading /> {:then comps}
function create_pending_block(ctx) {
	let loading;
	let current;
	loading = new Loading({});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let pagetitle;
	let t;
	let await_block_anchor;
	let promise;
	let current;

	pagetitle = new PageTitle({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: true,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 4,
		blocks: [,,,]
	};

	handle_promise(promise = /*compsPromise*/ ctx[0], info);

	return {
		c() {
			create_component(pagetitle.$$.fragment);
			t = space();
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			mount_component(pagetitle, target, anchor);
			insert(target, t, anchor);
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			const pagetitle_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				pagetitle_changes.$$scope = { dirty, ctx };
			}

			pagetitle.$set(pagetitle_changes);
			update_await_block_branch(info, ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(pagetitle.$$.fragment, local);
			transition_in(info.block);
			current = true;
		},
		o(local) {
			transition_out(pagetitle.$$.fragment, local);

			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			destroy_component(pagetitle, detaching);
			if (detaching) detach(t);
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function instance($$self) {
	let compsPromise = get("comps");
	const sixMonthsAgo = new Date();
	sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

	const currentComps = allComps => {
		return allComps.filter(comp => new Date(comp.start) >= sixMonthsAgo);
	};

	const pastComps = allComps => {
		return allComps.filter(comp => new Date(comp.start) < sixMonthsAgo);
	};

	return [compsPromise, currentComps, pastComps];
}

class Play extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Play;